import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post-list"
import PageSelector from "../components/page-selector"

const LatestPosts = ({ data, location, pageContext }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const posts = data.allMdx.edges
  const currentPage = pageContext.currentPage
  const numPages = pageContext.numPages

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={currentPage === 1 ? "Página Inicial" : "Página " + currentPage}
      />
      <PostList posts={posts} />
      <PageSelector
        currentPage={currentPage}
        numPages={numPages}
        urlPrefix={"/"}
      />
    </Layout>
  )
}

export default LatestPosts

export const latestPostsQuery = graphql`
  query latestPostsQuery($skip: Int!, $limit: Int!) {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { ne: "page" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            categories
            featuredImageCaption
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 900, layout: CONSTRAINED)
              }
            }
            featuredImageAlt
          }
        }
      }
    }
  }
`
